<template>
  <div class="bg" style="margin: 8px 6px 0 6px">
    <div class="bg-white con">
      <div class="tit">检查更新</div>
      <div class="df mb10">
        {{ status }}
        <div class="check" @click="check">检查更新</div>
      </div>
      <el-radio v-model="radio" label="1">有新版本自动安装更新</el-radio>
    </div>
  </div>
</template>
<script>
import { ipcApiRoute, specialIpcRoute } from "@/api/main";
export default {
  data() {
    return {
      status: "当前已是最新版本：1.2.1",
      progress:'',
      percentNumber:'',
      radio: false,
    };
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.$ipc.removeAllListeners(specialIpcRoute.appUpdater);
      this.$ipc.on(specialIpcRoute.appUpdater, (event, result) => {
        result = JSON.parse(result);
        console.log('upload',result);
        this.status = result.status;
        if (result.status == 3) {
          this.progress = result.desc;
          this.percentNumber = result.percentNumber;
        } else {
          this.$message.info(result.desc);
        }
      });
    },
    check() {
      this.$ipc.invoke(ipcApiRoute.checkForUpdater).then((r) => {
        console.log("check", r);
      });
    },
  },
};
</script>
<style lang="less" scoped>
.con {
  padding: 30px;
  text-align: left;
  font-size: 15px;

  .tit {
    font-size: 17px;
    color: #000;
    margin-bottom: 12px;
    letter-spacing: 1px;
  }

  .check {
    background: #0052d9;
    border-radius: 0.1rem;
    padding: 0.02rem 0.1rem;
    color: #fff;
    margin-left: 10px;
  }
}
</style>
