<template>
  <div class="bg">
    <div class="box">
      <div class="logo"><img src="@/assets/logo.png" /></div>
      <div v-html="content"></div>
    </div>
  </div>
</template>
<script>

export default {
  data() {
    return {
      content: `<div class="text"><p>非相人工智能是以AI数字人直播为核心业务的人工智能公司，面向电商、直播、XR演唱会、商业发布会、社交媒体传播、虚拟人助理和客服等多应用场景，实时创作能力的SaaS企业，核心技术为计算机视觉方面的深度学习、云渲染方面的人工智能技术。</p><p>公司创始团队主要由前视频、影视行业资深专家组成，解决虚拟人制作和运营有关所有技术问题的一站式SaaS解决方案。</p></div>`
    };
  },
  mounted() { // 渲染视频

  },
  methods: {
    test() {
      //
    },
  }
};
</script>
<style lang="less" scoped>
.box {
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.15);
  background: #fff;
  margin: 1.8rem 0.6rem;
  margin-bottom: 0;
  width: 72vw;
  height: 82vh;
  border-radius: 9px;

  .logo {
    width: 3rem;
    height: 3rem;
    background: #192a5d;
    border-radius: 2rem;
    overflow: hidden;
    position: relative;
    left: 44%;
    top: -12%;

    img{
      width: 3rem;
      height: 3rem;
    }
  }
}


::v-deep {

  p {
    max-width: 100%;
    text-align: left;
    text-indent: 24px;
    font-size: 16px;
  }

  .text {
    padding: 30px;
    padding-top: 0px;
  }
}
</style>
